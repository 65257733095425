<script lang="ts" setup></script>

<template>
	<svg width="159" height="20" viewBox="0 0 159 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.91977 0V15.1339H23.6623V20H0V0H5.91977Z" fill="#25282B" />
		<path d="M53.0337 0V4.86623H34.3045V7.52514H50.542V12.3077H34.3045V15.1339H53.0337V20H28.3849V0H53.0337Z" fill="#25282B" />
		<path
			d="M68.8096 11.0871H75.7495L72.2878 5.63551L68.8096 11.0871ZM75.0637 3.65567e-05L87.7728 20H81.3848L78.6926 15.7361H65.8664L63.1406 20H56.7526L69.4786 3.65567e-05H75.0637Z"
			fill="#25282B"
		/>
		<path
			d="M97.4119 15.134H111.342C111.955 15.1004 112.479 14.9054 112.914 14.5486C113.348 14.1475 113.56 13.6737 113.549 13.1273V6.87313C113.549 6.30433 113.332 5.83076 112.897 5.45143C112.473 5.05034 111.955 4.85528 111.342 4.86626H97.4119V15.134ZM112.462 3.65567e-05C114.313 3.65567e-05 115.94 0.585205 117.345 1.75594C118.783 2.92648 119.486 4.32562 119.452 5.95315V14.0637C119.452 15.6916 118.744 17.0903 117.328 18.2609C115.924 19.4206 114.302 20 112.462 20H91.4921V3.65567e-05H112.462Z"
			fill="#25282B"
		/>
		<path
			d="M147.82 0.130507C146.126 0.130507 144.49 0.798129 143.279 1.98304L141.223 3.99689L137.274 0.130507H125.468L135.341 9.34407L124.153 19.9709H133.249C134.945 19.9709 136.585 19.3135 137.812 18.1418L141.223 14.8841L146.548 19.9709H158.293L147.104 9.34407L156.978 0.130507H147.82Z"
			fill="url(#paint0_linear_333_2213)"
		/>
		<path d="M125.51 0.130366C130.014 4.03289 136.505 8.18697 141.265 3.99675L137.316 0.130366H125.51Z" fill="url(#paint1_linear_333_2213)" />
		<path d="M141.223 14.8841L146.548 19.9709H158.293C153.474 15.4837 148.882 13.4682 145.417 13.468C143.709 13.468 142.275 13.9583 141.223 14.8841Z" fill="url(#paint2_linear_333_2213)" />
		<defs>
			<linearGradient id="paint0_linear_333_2213" x1="125.036" y1="20.057" x2="159.086" y2="-0.17047" gradientUnits="userSpaceOnUse">
				<stop stop-color="#800000" />
				<stop offset="0.108827" stop-color="#800000" />
				<stop offset="0.507213" stop-color="#FF2400" />
				<stop offset="0.538563" stop-color="#FF2400" />
				<stop offset="1" stop-color="#FF7F50" />
			</linearGradient>
			<linearGradient id="paint1_linear_333_2213" x1="133.387" y1="6.03304" x2="133.387" y2="-2.56527" gradientUnits="userSpaceOnUse">
				<stop stop-color="#800000" />
				<stop offset="1" stop-color="#FF7F50" />
			</linearGradient>
			<linearGradient id="paint2_linear_333_2213" x1="149.758" y1="13.1128" x2="149.758" y2="22.5551" gradientUnits="userSpaceOnUse">
				<stop stop-color="#800000" />
				<stop offset="1" stop-color="#FF7F50" />
			</linearGradient>
		</defs>
	</svg>
</template>

<style scoped lang="scss">
	.logo {
		svg {
			height: 20px;
			width: auto;
		}
	}
</style>
